import { ReactComponent as IconContract } from '../../images/3-contract.svg';
import { ReactComponent as IconCrypto } from '../../images/crypto.svg';
import { ReactComponent as IconHouseValue } from '../../images/houseValue.svg';

export const data = [
  {
    title: '4 - Approve Documents',
    desc: 'Get easy and secure access to property documents and reports.',
    Illustration: IconContract,
  },
  {
    title: '5 - Get Stake with stablecoins',
    desc: 'Once approved, you can get stake from our smartcontract (fractional ownership) verifiable on blockchain',
    Illustration: IconCrypto,
  },
  {
    title: '6 - Property Preparation',
    desc: 'Legal entity gets formed, property gets purchased and prepared. All reports get uploaded and become available.',
    Illustration: IconHouseValue,
  },
]