import { data } from './data'
import React from 'react'
import { Benefit } from '../../components'

export const Showcase = () => {
  return (
    <div id="showcase">
    <div className="space-y-8 text-center md:max-w-[32.563rem] mt-20 m-auto md:hidden">
      <h2 className="text-3xl font-bold">How does it work?</h2>
    </div>
    <ul
      className="container flex flex-col items-center justify-center gap-20 p-5 m-auto lg:flex-row lg:gap-5 lg:justify-between max-w-[70rem]"
    >
      {data.map((item, idx) => {
        const isEven = idx % 2 === 0 ? true : false
        return (
          <li
            key={item.title}
            className={
              isEven
                ? 'md:ml-[5rem] lg:ml-0 lg:mt-[2rem]'
                : 'md:mr-[5rem] lg:mr-0 lg:mb-[4rem]'
            }
          >
            <Benefit info={item} idx={idx} />
          </li>
        )
      })}
    </ul>
    </div>
  )
}
