import { data } from './data'
import React from 'react'
import { Benefit } from '../../components'

import scrollAnimation from '../hero//lottie_scroll.json'
import Lottie from 'lottie-react'
import { motion } from 'framer-motion'
import { framer_icon  } from '../hero/framer'

export const Showcase3 = () => {
  return (
    <div id="showcase3" className="md:mt-60">
    <div className="space-y-8 text-center md:max-w-[32.563rem] m-auto">
      <h2 className="text-3xl font-bold">What you get?</h2>
    </div>
    
    <ul
      className="container flex flex-col items-center justify-center gap-20 p-5 m-auto lg:flex-row lg:gap-5 lg:justify-between max-w-[70rem]"
    >
      {data.map((item, idx) => {
        const isEven = idx % 2 === 0 ? true : false
        return (
          <li
            key={item.title}
            className={
              isEven
                ? 'md:ml-[5rem] lg:ml-0 lg:mb-[4rem]'
                : 'md:mr-[5rem] lg:mr-0 lg:mt-[2rem]'
            }
          >
            <Benefit info={item} idx={idx} />
          </li>
        )
      })}
    </ul>
    <div className="flex flex-col items-center gap-8 p-5 md:max-w-[28.563rem] md:m-auto md:bg-bg-hero-squiggle md:bg-contain bg-no-repeat bg-center ">
<motion.a href="#summary" className="max-w-[5rem]" {...framer_icon}>
<Lottie animationData={scrollAnimation} loop={true} />
</motion.a>
</div>
    </div>
  )
}
