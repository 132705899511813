import { ReactComponent as IconReturns } from '../../images/4-returns.svg';
import { ReactComponent as IconManagement } from '../../images/5-management.svg';
import { ReactComponent as IconHouseValue } from '../../images/houseValue.svg';

export const data = [
  {
    title: '1 - Potential rewards',
    desc: 'Get rights to recieve potential rewards based on your stake. (fractional ownership)',
    Illustration: IconReturns,
  },
  {
    title: '2 - Property appreciation',
    desc: 'Get benefits of potential property appreciation over time.',
    Illustration: IconHouseValue,
  },
  {
    title: '3 - Professional Management',
    desc: 'All of the properties are managed by the best of the industry professionals.',
    Illustration: IconManagement,
  },
]