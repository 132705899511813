import React from 'react';
import './index.css';

import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Home from './pages';
import PrivacyPolicy from './pages/privacy';
import Terms from './pages/terms';
import NotFoundPage from './pages/NotFoundPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home/>} exact />
        <Route path='/privacy' element={<PrivacyPolicy/>} exact />
        <Route path='/terms' element={<Terms/>} exact />
        <Route path="*" element={<NotFoundPage/>} />
      </Routes>
    </Router>
  );
}

export default App;