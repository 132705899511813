import { ReactComponent as IconExplorer } from '../../images/searchProperty.svg';
import { ReactComponent as IconLock } from '../../images/lock.svg';
import { ReactComponent as IconKYC } from '../../images/kyc.svg';

export const data = [
  {
    title: '1 - Authenticate',
    desc: 'Connect with your Web3 wallet',
    Illustration: IconLock,
  },
  {
    title: '2 - Verify your identity (KYC/AML)',
    desc: 'Upload documents and take a selfie',
    Illustration: IconKYC,
  },
  {
    title: '3 - Explore open offers',
    desc: 'Get access to gated comminity',
    Illustration: IconExplorer,
  },
]
