import React from 'react';
//import SignIn from '../components/SignIn';
import ScrollToTop from '../components/ScrollToTop';

function PrivacyPolicy() {
  return (
    <>
      <ScrollToTop />
    {/*  <SignIn />  */}
    </>
  );
}

export default PrivacyPolicy;
