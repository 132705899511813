import { Percent, TypesOfOffersIcon, ReportsIcon } from '../../components'

export const data = [
  {
    title: 'Types of offers',
    desc: 'For some of the offers you might need to be an accredited investor',
    price: 0,
    isFree: true,
    Icon: TypesOfOffersIcon,
    options: [
      'Vacation Rentals',
      'Long-Term Rentals',
      'New development projects',
      'Large Commercial Assets',
    ],
  },
  {
    title: 'Detailed Reports',
    desc: 'Get easy and secure access to property documents and reports.',
    price: 0,
    isFree: false,
    Icon: ReportsIcon,
    options: [
      'Property Purchase',
      'Management Records',
      'Contracts',
      'Tax forms',
    ],
  },
  {
    title: 'Potential Rewards',
    desc: 'Recieve rewards directly to your Web3 wallet based on your stake',
    price: 0,
    isFree: true,
    Icon: Percent,
    options: [
      'Rewards from rent, lease, sales',
      'Partual liquidity based on offer type',
      'Tax benefits',
      'Access to gated comminity',
    ],
  },
]
