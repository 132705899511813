import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import PageNotFound from '../images/404-Error.gif';
import Footer from '../sections/footer';

const Img = styled.img`
  width: 30%;
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const LinkToHome = styled.p`
  margin-top: 25px;
  font-size: 45px;
  line-height: 50px;
`;

const P = styled.p`
  margin-bottom: 5px;
  font-size: 11px;
  line-height: 24px;
`;

function NotFoundPage() {
        return <div>
            <LinkToHome style={{textAlign:"center"}}>
              <Link to="/">Go to Home </Link>
            </LinkToHome>
            <Img alt='404 Error' src={PageNotFound} />
            <P style={{textAlign:"center"}}> 
                <a href="https://storyset.com/web">Web illustrations by Storyset</a>
            </P>
            <Footer />
          </div>;
}

export default NotFoundPage;