import { Card } from '../../components'
import { data } from './data'

export const Summary = () => {
  return (
    <section id="summary" className="container p-5 m-auto my-[10rem] space-y-10 md:space-y-20">
      <div className="space-y-8 text-center md:max-w-[32.563rem] m-auto">
        <h2 className="text-3xl font-bold">Let's recap:</h2>
        <p className="text-gray">
          1 - Authenticate, 2 - Verify Identity, 3 - Get Stake, 4 - Relax. 
        </p>
      </div>
      <ul className="flex flex-col gap-20 md:gap-10 md:flex-row md:justify-center">
        {data.map(item => {
          return <Card key={item.title} info={item} ltr={item.isFree} />
        })}
      </ul>
    </section>
  )
}
