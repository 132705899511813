import { Navbar } from '../components'
import { Hero, Notify, Summary, Showcase, Showcase2, Showcase3 } from '../sections'
import Footer from '../sections/footer'
import { MotionConfig } from 'framer-motion'

function Home() {
  return (
    <MotionConfig reducedMotion="user">
      <div className="relative flex flex-col justify-center min-h-screen">
        <Navbar />
        <Hero />
      </div>
      <Showcase />
      <Showcase2 />
      <Showcase3 />
      <Summary />
      <Notify />
      <Footer />
    </MotionConfig>
  )
}

export default Home
